import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { Input } from '../../ui';

export default function SearchablePlaceField({ name, label, required, block }) {
  const [value, , { setValue }] = useField(name);
  const [autocomplete, setAutocomplete] = useState(null);
  const [input, setInput] = useState(null);

  const onLoad = autocomplete => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = async () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      const formattedPlace = {
        name: place.name,
        address: place.formatted_address,
        city:
          place.address_components?.find(c => c.types.includes('locality'))
            ?.long_name ??
          place.address_components?.find(c => c.types.includes('postal_town'))
            ?.long_name ??
          place.address_components?.find(c => c.types.includes('sublocality'))
            ?.long_name ??
          place.address_components?.find(c =>
            c.types.includes('administrative_area_level_1'),
          )?.long_name,
        country: place.address_components?.find(c =>
          c.types.includes('country'),
        )?.long_name,
        lat: place.geometry.location.lat(),
        lon: place.geometry.location.lng(),
        isCity: place.types.includes('locality'),
      };

      setValue(formattedPlace);
      setInput(formatPlace(formattedPlace));
    }
  };

  const formatPlace = p => {
    const isCity = p.isCity;
    if(isCity || !p.city){
      return `${p.address}`;
    }
    return `${p.name}, ${p.city}, ${p.country}`;
  };

  useEffect(() => {
    if (value.value && input === null) {
      setInput(formatPlace(value.value));
    }
  }, [value?.value]);

  return (
    <div className={block && 'w-full'}>
      <div className="font-medium mb-2">
        {label}
        <span className="text-red-500">{required ? ' *' : ''}</span>
      </div>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <Input
          type="text"
          value={input}
          onChange={e => setInput(e.target.value)}
        />
      </Autocomplete>
    </div>
  );
}
