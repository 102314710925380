import { Field, Form, useField } from 'formik';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import {
  ButtonReset,
  Dropdown,
  PopoverContainer,
  PopoverItemButton as RawPopoverItemButton,
  PopoverMenu,
  Stack,
} from '@koob/margaret';
import { Text, Card, FormLabel, InlineFormField, Divider, FormField } from 'ui';
import {
  TextField,
  SegmentedControlField,
  StepperField,
  UnsavedFormTrigger,
  FormNavigationBlocker,
  DateRangePicker,
} from 'components/Fields';
import PeriodsField from 'components/Fields/PeriodsField';
import IcBasicDeal from 'components/icons/IcBasicDeal';
import IcLastMinute from 'components/icons/IcLastMinute';
import IcEarlyBooker from 'components/icons/IcEarlyBooker';
import PromotionsNightsNumberField from 'components/Fields/PromotionsNightsNumberField';
import { useState } from 'react';
import { IcAddCircle, IcCheckMark } from '../icons';
import VisuallyHidden from '../VisuallyHidden';
import { IoCloseOutline } from 'react-icons/io5';
import { VscChevronDown } from 'react-icons/vsc';
import KoobModal from 'components/Koob/KoobModal';
import { isEmpty } from 'lodash';
import ToggleSwitch from 'components/Fields/ToggleSwitch';

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing()};
  grid-template-rows: auto;
  grid-template-areas: ${({ gridTemplateAreas }) =>
    gridTemplateAreas
      ? gridTemplateAreas
      : `'top top'
       'deal period'
       'promotionParams period'
       'cancellationPolicies cancellationPolicies'
      `};
`;

export const Cell = styled(Stack).attrs({
  direction: 'column',
  gutterSize: 0.5,
  size: 'full',
  alignX: 'stretch',
})`
  ${({ gridArea }) =>
    gridArea &&
    `
    grid-area: ${gridArea};
  `}
`;

const TextPadding = styled(Text)`
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(2)};
`;

const DealWrapper = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
  alignY: 'center',
  alignX: 'center',
})`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) => theme.primary};
`;

const Wrapper = styled(Stack).attrs({ direction: 'column' })`
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
`;

const DropdownToggleWrapper = styled(Stack)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0.5)};
  box-shadow: 0 0 4px rgb(0 0 0 / 0.08);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.spacing(0.25)};
  justify-content: space-between;
  align-items: center;
`;

const DropdownToggle = ({ children }) => {
  return (
    <DropdownToggleWrapper>
      {children} <VscChevronDown aria-hidden="true" size={16} />
    </DropdownToggleWrapper>
  );
};

const PopoverItemButton = styled(RawPopoverItemButton)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};

  color: ${({ theme }) => theme.textLight};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const SummaryListHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  margin-block: ${({ theme }) => theme.spacing(1)}
    ${({ theme }) => theme.spacing(0.5)};
`;

const ClearAllButton = styled(ButtonReset).attrs({ type: 'button' })`
  color: ${({ theme }) => theme.secondary};
  text-decoration: underline;

  ${({ empty }) =>
    empty
      ? css`
          color: ${({ theme }) => theme.textLighter};
        `
      : null};
`;

const DisplayListView = styled.ul`
  list-style: none;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(1)};
  margin: 0;
  border: solid 1px rgba(0, 0, 0, 0.12);
  border-radius: ${({ theme }) => theme.borderRadius.default};
  width: 100%;
  overflow-y: auto;
  max-height: 12ch;
`;

const DisplayListItem = styled.li`
  padding: ${({ theme }) => theme.spacing(0.25)} 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;

const SelectionLabel = styled.span`
  text-transform: uppercase;
`;

const Deal = ({ displayName, icon }) => {
  return (
    <DealWrapper>
      {icon}
      <Text type="body" color="text">
        {displayName}
      </Text>
    </DealWrapper>
  );
};

const Deals = {
  basic_deal: 'basic_deal',
  early_booker: 'early_booker',
  last_minute: 'last_minute',
};

const CumulativePromotionsField = ({ name, promotions }) => {
  const { t } = useTranslation('promotionForm');
  const theme = useTheme();
  const [{ value }, , { setValue }] = useField(name);
  const [{ value: promotionType }] = useField('params.type');
  const [
    { value: isRequiredSupplement },
    ,
    { setValue: setIsRequiredSupplement },
  ] = useField('mandatorySupplement');
  const supplementType = ['supplement', 'supplement_percent'];
  const isDesabled = supplementType.includes(promotionType?.value);

  const handleClick = selected => {
    if (value?.length > 0) {
      if (value?.some(({ id }) => id === selected.id)) {
        setValue(value.filter(({ id }) => id !== selected.id));
      } else {
        setValue([...value, { ...selected }]);
      }
    } else {
      setValue([{ ...selected }]);
    }
  };

  const handleRemoveAll = () => {
    setValue([]);
  };

  const handleRemoveById = id => {
    const nextState = value?.filter(item => item.id !== id);
    setValue(nextState);
  };

  return (
    <Wrapper>
      <FormField>
        {supplementType.includes(promotionType?.value) && (
          <Stack direction="column" className="mb-1">
            <FormLabel>{t('mandatorySupplement')}</FormLabel>
            <ToggleSwitch
              checked={isRequiredSupplement}
              onChange={() => {
                setIsRequiredSupplement(!isRequiredSupplement);
                setValue([]);
              }}
              fullWidth
            />
          </Stack>
        )}
        <FormLabel>{t('cumulativePromotions.select')}</FormLabel>
        <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
          {/* disable supplement cumulativity for v1 */}
          <Dropdown
            trigger={
              <div
                className={isDesabled ? 'bg-gray-200 cursor-not-allowed' : ''}
              >
                <DropdownToggle>
                  {t('cumulativePromotions.select')}
                </DropdownToggle>
              </div>
            }
            disabled={isDesabled}
          >
            <PopoverContainer>
              <PopoverMenu>
                {promotions?.map(({ id, name }) => (
                  <PopoverItemButton
                    key={id}
                    onClick={() => handleClick({ id, name })}
                    type="button"
                  >
                    <Stack
                      size="full"
                      alignX="space-between"
                      alignY="center"
                      gutterSize={0.5}
                    >
                      <Stack alignY="center" gutterSize={0.25}>
                        <Text type="body">
                          <SelectionLabel>{name}</SelectionLabel>
                        </Text>
                      </Stack>
                      {value?.some(bed => bed.id === id) ? (
                        <IcCheckMark size={24} color={theme.primary} />
                      ) : (
                        <IcAddCircle size={24} color={theme.textLight} />
                      )}
                    </Stack>
                  </PopoverItemButton>
                ))}
              </PopoverMenu>
            </PopoverContainer>
          </Dropdown>
        </Stack>
      </FormField>
      <SummaryListHeader>
        <span style={{ color: theme.textLight }}>
          {t('cumulativePromotions.selected')} &middot; {value?.length ?? 0}
        </span>
        <ClearAllButton
          empty={(value?.length ?? 0) === 0}
          onClick={handleRemoveAll}
        >
          {t('applicableRooms.clear')}
        </ClearAllButton>
      </SummaryListHeader>
      {value?.length > 0 && (
        <DisplayListView>
          {value?.map(item => (
            <DisplayListItem key={item?.id}>
              <SelectionLabel>{item?.name?.toUpperCase()}</SelectionLabel>
              <ButtonReset onClick={() => handleRemoveById(item?.id)}>
                <VisuallyHidden>
                  {t('applicableRooms.deleteFromSelection', {
                    value: item?.name,
                  })}
                </VisuallyHidden>
                <IoCloseOutline
                  color={theme.textLighter}
                  aria-hidden="true"
                  size={24}
                />
              </ButtonReset>
            </DisplayListItem>
          ))}
        </DisplayListView>
      )}
    </Wrapper>
  );
};

const TriggerTextField = styled(Field)`
  width: 100%;
  border: 0px;
  &:focus {
    outline: none;
  }
  input:focus {
    outline: none;
  }
`;

const OrganizationDropdownToggle = ({ children, onChange }) => {
  const [value, setValue] = useState('');
  const handleChange = e => {
    setValue(e.target?.value);
    onChange(e.target?.value);
  };
  const handleOnClick = () => {
    if (!value) {
      onChange(' ');
    }
  };
  return (
    <TriggerTextField
      type="text"
      placeholder={children}
      value={value}
      onClick={handleOnClick}
      onChange={handleChange}
      data-cy={'trigger-text-field'}
    />
  );
};
export const OrganizationsField = ({ name, disabled, organizations }) => {
  const theme = useTheme();
  const [{ value }, , { setValue }] = useField(name);
  const { t } = useTranslation('promotionForm');

  const [values, setValues] = useState(organizations);
  const handleValuesChange = orgName => {
    const newValues = organizations.filter(v =>
      v.displayName.trim().toLowerCase().includes(orgName.toLowerCase().trim()),
    );

    if (!orgName || !orgName.trim() || newValues.length === 0) {
      setValues(organizations);
      return;
    }
    setValues(newValues);
  };

  const handleClick = selectedElement => {
    if (value?.length > 0) {
      if (value?.some(({ id }) => id === selectedElement.id)) {
        setValue(value.filter(({ id }) => id !== selectedElement.id));
      } else {
        setValue([...value, { ...selectedElement }]);
      }
    } else {
      setValue([{ ...selectedElement }]);
    }
  };

  const handleRemoveAll = () => {
    setValue([]);
  };

  const handleRemoveById = id => {
    const nextState = value?.filter(item => item.id !== id);
    setValue(nextState);
  };

  return (
    <Wrapper>
      <FormField>
        <FormLabel>{t('organizations.applicableOnTo')}</FormLabel>
        {!disabled && (
          <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
            <Dropdown
              trigger={
                <DropdownToggleWrapper>
                  <OrganizationDropdownToggle onChange={handleValuesChange}>
                    {t('organizations.searchToToAdd')}
                  </OrganizationDropdownToggle>
                </DropdownToggleWrapper>
              }
            >
              <PopoverContainer>
                <PopoverMenu>
                  {values?.map(({ id, displayName }) => (
                    <PopoverItemButton
                      key={id}
                      onClick={() => handleClick({ id, displayName })}
                      type="button"
                      data-cy={`select-${displayName?.split(' ').join('-').toLowerCase()}`}
                    >
                      <Stack
                        size="full"
                        alignX="space-between"
                        alignY="center"
                        gutterSize={0.5}
                      >
                        <Stack alignY="center" gutterSize={0.25}>
                          <Text type="body">
                            <SelectionLabel>{displayName}</SelectionLabel>
                          </Text>
                        </Stack>
                        {value?.some(element => element.id === id) ? (
                          <IcCheckMark size={24} color={theme.primary} />
                        ) : (
                          <IcAddCircle size={24} color={theme.textLight} />
                        )}
                      </Stack>
                    </PopoverItemButton>
                  ))}
                </PopoverMenu>
              </PopoverContainer>
            </Dropdown>
          </Stack>
        )}
      </FormField>
      <SummaryListHeader>
        <span style={{ color: theme.textLight }}>
          {t('organizations.toSelected')} &middot; {value?.length ?? 0}
        </span>
        <ClearAllButton
          empty={(value?.length ?? 0) === 0}
          onClick={handleRemoveAll}
        >
          {t('organizations.clear')}
        </ClearAllButton>
      </SummaryListHeader>
      {value?.length > 0 && (
        <DisplayListView>
          {value?.map(item => (
            <DisplayListItem key={item?.id}>
              <SelectionLabel>
                {item?.displayName?.toUpperCase()}
              </SelectionLabel>
              <ButtonReset onClick={() => handleRemoveById(item?.id)}>
                <VisuallyHidden>
                  {t('applicableTo.deleteFromSelection', {
                    value: item?.displayName,
                  })}
                </VisuallyHidden>
                <IoCloseOutline
                  color={theme.textLighter}
                  aria-hidden="true"
                  size={24}
                />
              </ButtonReset>
            </DisplayListItem>
          ))}
        </DisplayListView>
      )}
    </Wrapper>
  );
};
const PromotionBookingPeriodsModal = ({ open, setOpen, index }) => {
  const { t } = useTranslation('promotionForm');
  const [field, meta] = useField(`periods[${index}]`);
  const period = field?.value;
  const overflowValue =
    period && period?.bookingPeriods?.length >= 2 ? 'auto' : 'visible';
  const isDesabled = Boolean(meta.error || meta.touched);

  return (
    <KoobModal
      open={open}
      setOpen={setOpen}
      widthClass="sm:max-w-xl xl:max-w-2xl overflow-visible"
    >
      <div className={`max-h-[600px] overflow-y-${overflowValue}`}>
        <Text type="h3" className="text-center mb-2">
          {t('bookingPeriods.label')}
        </Text>
        <div className="w-full flex justify-between my-2 py-2 border-b">
          <span style={{ flex: '0 0 auto' }}>
            {t('bookingPeriods.validityPeriod')}
          </span>
          <DateRangePicker
            disabled={true}
            style={{ width: '100%' }}
            value={[period?.startAt, period?.endAt]}
            minDate={new Date()}
          />
        </div>
        <PeriodsField
          name={`periods[${index}].bookingPeriods`}
          canRemoveLastElement
          required
        />
      </div>
      <div className="w-full mt-5 flex justify-end">
        <Button
          type="button"
          variant="primary"
          onClick={() => setOpen(false)}
          disabled={isDesabled}
        >
          {t('misc:save')}
        </Button>
      </div>
    </KoobModal>
  );
};

const PromotionBookingPeriods = ({ index }) => {
  const { t } = useTranslation('promotionForm');
  const [showBookingPeriodsModal, setShowBookingPeriodsModal] = useState(false);
  const [field] = useField(`periods[${index}]`);
  const period = field?.value;
  const isDisabled = isEmpty(period?.startAt) || isEmpty(period?.endAt);
  const periodBookingCounter = period?.bookingPeriods?.length;

  return (
    <>
      <Stack size="full" alignY="center" className="my-3">
        <span style={{ flex: '0 0 auto' }}>{t('bookingPeriods.title')}</span>
        <Stack
          size="full"
          alignX="end"
          alignY="center"
          gutterSize={1}
          className="w-full"
        >
          <span>
            {t('bookingPeriods.configured', { count: periodBookingCounter })}
          </span>
          <Button
            variant="primary"
            type="button"
            onClick={() => setShowBookingPeriodsModal(true)}
            disabled={isDisabled}
          >
            {t('bookingPeriods.label')}
          </Button>
        </Stack>
      </Stack>
      <PromotionBookingPeriodsModal
        open={showBookingPeriodsModal}
        setOpen={setShowBookingPeriodsModal}
        index={index}
      />
    </>
  );
};

const PromotionForm = ({
  promotion,
  style,
  cancellationPolicies,
  setUnsavedData,
  promotionField,
  isDuplicate,
  applicableTo,
  toOrganizations = [],
  values,
  isSubmitting,
  touched,
  isValid,
  dirty,
  dealTypeSelectionAllowed,
  promotionsThatCanBeLinked,
  hasBookingPeriod,
}) => {
  const { t } = useTranslation('promotionForm');
  const theme = useTheme();

  const stateOptions = [
    { value: 'draft', label: <TextPadding>{t('states.draft')}</TextPadding> },
    {
      value: 'operational',
      label: <TextPadding>{t('states.operational')}</TextPadding>,
    },
    {
      value: 'archived',
      label: <TextPadding>{t('states.archived')}</TextPadding>,
    },
  ];

  const dealOptions = [
    {
      value: Deals.basic_deal,
      label: (
        <Deal
          displayName={t('deals.basicDeal')}
          icon={<IcBasicDeal size={32} />}
        />
      ),
    },
    {
      value: Deals.early_booker,
      label: (
        <Deal
          displayName={t('deals.earlyBooker')}
          icon={<IcEarlyBooker size={32} />}
        />
      ),
    },
    {
      value: Deals.last_minute,
      label: (
        <Deal
          displayName={t('deals.lastMinute')}
          icon={<IcLastMinute size={32} />}
        />
      ),
    },
  ];

  var LinkCancel = '..';
  var isDuplicated = false;
  var disabledButton = false;
  if (isDuplicate) {
    isDuplicated = true;
    LinkCancel = '../..';
  } else if (promotion?.state === 'archived') {
    isDuplicated = false;
    disabledButton = true;
  }

  return (
    <Form style={style}>
      <FormNavigationBlocker />
      <Stack direction="column" gutterSize={1} size="full" alignX="stretch">
        <Grid style={{ gridTemplateAreas: style?.gridTemplateAreas }}>
          <Card style={{ gridArea: 'top' }}>
            <Stack
              gutterSize={4}
              size="full"
              alignY="flex-end"
              alignX="space-between"
            >
              <div style={{ width: 'calc(50% - 32px)' }}>
                <TextField label={t('promotionName')} name="name" />
              </div>
              {isDuplicated || promotion?.state !== 'archived' ? (
                <SegmentedControlField
                  name="state"
                  options={stateOptions}
                  style={{ display: 'flex' }}
                />
              ) : null}
            </Stack>
          </Card>
          {dealTypeSelectionAllowed && (
            <Cell gridArea="deal">
              <Text type="h2">{t('subTitles.deal')}</Text>
              <Card>
                <Stack gutterSize={0.5} direction="column" size="full">
                  <Stack size="full" alignX="center">
                    <SegmentedControlField name="kind" options={dealOptions} />
                  </Stack>
                  <Stack
                    direction="row"
                    alignY="center"
                    style={{ paddingBlock: theme.spacing(0.5) }}
                  >
                    {values?.kind && (
                      <PromotionsNightsNumberField
                        name="numberOfNights"
                        kind={values?.kind}
                      />
                    )}
                  </Stack>
                  <Divider />
                  <InlineFormField style={{ alignSelf: 'stretch' }}>
                    <FormLabel variant="bare">{t('minRoomsToBook')}</FormLabel>
                    <Stack style={{ marginLeft: 'auto' }}>
                      <StepperField name="minRooms" />
                    </Stack>
                  </InlineFormField>
                </Stack>
              </Card>
            </Cell>
          )}

          <Cell gridArea="period">
            <Text type="h2">{t('subTitles.period')}</Text>
            <Card>
              <div style={{ width: '500px' }} />
              <PeriodsField
                required
                customPeriodFields={hasBookingPeriod && PromotionBookingPeriods}
                name="periods"
              />
            </Card>
          </Cell>

          <Cell gridArea="promotionParams">
            <Text type="h2">{t('subTitles.promotionParams')}</Text>
            <Card>
              {promotionField}
              <>
                <Divider />

                <CumulativePromotionsField
                  name="cumulativePromotions"
                  promotions={promotionsThatCanBeLinked}
                  applicableTo={applicableTo}
                />
              </>
              {applicableTo === 'to' && (
                <>
                  <Divider />

                  <OrganizationsField
                    name="organizations"
                    organizations={toOrganizations}
                  />
                </>
              )}
            </Card>
          </Cell>
          {cancellationPolicies}
        </Grid>
        <Stack gutterSize={0.5}>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="simple"
            type="button"
            to={LinkCancel}
          >
            {t('misc:cancel')}
          </Button>
          {isDuplicated ? (
            <Button type="submit" variant="primary" isLoading={isSubmitting}>
              {t('misc:save')}
            </Button>
          ) : (
            <Button
              type="submit"
              disabled={!dirty || !isValid || disabledButton}
              variant="primary"
              isLoading={isSubmitting}
              cypressId="save-promotion"
            >
              {t('misc:save')}
            </Button>
          )}
        </Stack>
      </Stack>
      {setUnsavedData && (
        <UnsavedFormTrigger
          dirty={dirty && touched}
          setIsUnsaved={setUnsavedData}
        />
      )}
    </Form>
  );
};

export default PromotionForm;
