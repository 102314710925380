import React, { useState } from 'react';
import ExperienceTypeIcon from './ExperienceTypeIcon';
import { Tab, Tabs, Text } from 'ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import KoobCard from 'components/Koob/KoobCard';
import ExperienceExportModal from './ExperienceExportModal';

const CustomTab = styled(Tab)`
  & {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
  }
`;

function ExperienceExportButton({ onClick, exportType }) {
  return (
    <button
      onClick={onClick}
      className="border-none text-gray-500 font-medium hover:text-orange-500 transition flex items-center space-x-1.5"
      type="button"
    >
      <svg className="h-4 w-4" height="1em" viewBox="0 0 384 512">
        <path d="M320 480H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H192V144c0 26.5 21.5 48 48 48H352V448c0 17.7-14.3 32-32 32zM240 160c-8.8 0-16-7.2-16-16V32.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4H240zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V163.9c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64zM208 240c0-8.8-7.2-16-16-16s-16 7.2-16 16V361.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l80 80c6.2 6.2 16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L208 361.4V240z" />
      </svg>
      <div className="text-sm">
        Export as {exportType === 'docx' ? 'docx' : 'pdf'}
      </div>
    </button>
  );
}

const ExperienceHeader = ({
  rightElement,
  values,
  showExportOptions,
  currentLocale,
}) => {
  const { t } = useTranslation('experiences');
  const [exportAs, setExportAs] = useState(null);

  return (
    <KoobCard className="mb-5">
      <div className="grid grid-cols-3 items-center">
        <div className="flex space-x-3 text-center text-gray-500">
          <ExperienceTypeIcon
            sizeClass="w-10 h-10"
            type={
              values?.type !== 'Activity' || values?.activity?.isExtra === false
                ? values?.type
                : 'Extra'
            }
          />
          <div className="mt-3 font-bold capitalize">
            {values?.type !== 'Activity' || values?.activity?.isExtra === false
              ? values?.type
              : t('form.services.extra')}
          </div>
        </div>

        <div className="">
          <Text type="h3">{values?.name}</Text>
        </div>

        <div className="flex justify-end">{rightElement}</div>
      </div>
      <div className="flex justify-between border-t mt-6 pt-4">
        <div className="flex gap-x-10 justify-start">
          {(values?.id && (
            <Tabs>
              <CustomTab data-cy="edit" to={`/experiences/edit/${values?.id}`} end>
                {t('edit')}
              </CustomTab>
              <CustomTab data-cy="promotions" to={`/experiences/edit/${values?.id}/promotions`}>
                {t('promotions')}
              </CustomTab>
              <CustomTab data-cy="compatibility" to={`/experiences/edit/${values?.id}/compatibility`}>
                {t('compatibility')}
              </CustomTab>
              {values?.type === 'Program' && (
                <CustomTab
                  to={`/experiences/edit/${values?.id}/recommendation`}
                >
                  {t('recommendation')}
                </CustomTab>
              )}
            </Tabs>
          )) || (
            <div className="mx-auto mt-2 text-red-500">
              {t('creationSubtitle')}
            </div>
          )}
        </div>

        {showExportOptions && values.id && (
          <div className="flex space-x-5">
            <ExperienceExportButton
              exportType="docx"
              onClick={() => setExportAs('docx')}
            />
            <ExperienceExportButton
              exportType="pdf"
              onClick={() => setExportAs('pdf')}
            />
          </div>
        )}
      </div>

      {showExportOptions && values.id && (
        <ExperienceExportModal
          open={!!exportAs}
          close={() => setExportAs(null)}
          experienceId={values.id}
          experienceName={values.name}
          exportFormat={exportAs}
          currentLocale={currentLocale}
        />
      )}
    </KoobCard>
  );
};

export default ExperienceHeader;
