import { Container, FormLabel } from 'ui';
import { Stack } from '@koob/margaret';
import {
  SearchableSelectField,
  TextField,
  SegmentedControlField,
  EditorField,
  MultiSearchableSelectField,
  AvatarField,
} from 'components/Fields';
import { gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import TextFieldButton from 'components/Fields/TextFieldButton';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import LocalMultiSearchableSelectField from '../../../../components/Fields/LocalMultiSearchableSelectField';
import MapsPlaceSearchField from '../../../../components/MapsPlaceSearchField';
import KoobTitle from '../../../../components/Koob/KoobTitle';
import { initialLocale } from '../../../../utils';
import { useApp } from '../../../../hooks';
import { IcBestMatch } from 'components/icons';
import SearchablePlaceField from '../../../../components/Fields/SearchablePlaceField';
import { getExperiences } from 'api/node/experiences';

export const GET_EXPERIENCES = gql`
  query getExperience(
    $search: String
    $type: ExperienceTypeValues
    $time: TransferTimeValues
    $excludedExperiences: [String!]
  ) {
    experiences(
      name: $search
      type: $type
      time: $time
      excludedExperiences: $excludedExperiences
    ) {
      edges {
        node {
          id: koobId
          name
          description
          translations {
            locale
            name
            description
          }
        }
      }
    }
  }
`;

export const GET_HOTELS = gql`
  query getHotels($search: String, $state: String) {
    hotels(first: 10, displayName: $search, state: $state) {
      edges {
        node {
          id
          displayName
          address
        }
      }
    }
  }
`;

function SearchExperience({ currentLocale, index }) {
  const { t } = useTranslation('experiences');
  const { locales } = useApp();

  const [translationsValue, , { setValue: setTranslations }] = useField(
    `programs[${index}].translations`,
  );
  const [experiencesValue] = useField(`programs[${index}].programExperiences`);

  const [oldLength, setOldLength] = useState(0);

  const trans = translationsValue?.value;
  const exps = experiencesValue?.value;

  useEffect(() => {
    if (exps?.length > oldLength) {
      let translations = [];

      locales.forEach(locale => {
        const existingTranslation =
          trans?.find(t => t.locale === locale.value) || {};
        const defaultTranslation = trans?.find(t => t.locale === 'en') || {};

        let programContent =
          existingTranslation?.program ?? defaultTranslation?.program ?? '';
        let toAppend = exps[exps.length - 1];
        let toAppendTranslation =
          toAppend?.translations?.find(t => t.locale === locale.value) ??
          toAppend?.translations?.find(t => t.locale === 'en') ??
          {};

        programContent += `<br/><h1><b>${toAppendTranslation?.name
          }</b></h1><p>${toAppendTranslation?.description ?? ''}</p><br>`;

        translations.push({
          locale: locale.value,
          title: existingTranslation?.title ?? defaultTranslation?.title,
          program: programContent,
        });
      });

      setTranslations(translations);
    }
    setOldLength(exps?.length ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exps]);

  return (
    <MultiSearchableSelectField
      label={t('form.searchExperiences')}
      name={`programs[${index}].programExperiences`}
      query={getExperiences}
      fromNode={true}
      variables={{
        locale: currentLocale.value,
      }}
      pathToEdges={['experiences', 'edges']}
      renderOption={option => option.name}
      renderSelectedOption={option => option.name}
    />
  );
}

export const DayForm = ({ index, currentLocale }) => {
  const { t } = useTranslation('experiences');
  const [accomodationType] = useField(`programs[${index}].accomodationType`);
  const [isIncludedAccomodation] = useField(`programs[${index}].isIncludedAccomodation`);
  const [experiences] = useField(`programs[${index}].programExperiences`);
  const [attachment] = useField(`programs[${index}].picture`);
  const [program, , { setValue: setProgram }] = useField(
    `programs[${index}].program`,
  );
  const [suggestedExperience] = useField(
    `programs[${index}].suggestedExperiences`,
  );
  const [includedExperience] = useField(
    `programs[${index}].includedExperiences`,
  );

  const [{ value: programDatas }] = useField(`programs`);

  const suggestedIds = [];
  const incluedIds = [];

  programDatas?.forEach(item => {
    item.suggestedExperiences?.forEach(exp => {
      suggestedIds.push(exp.id);
    });
    item.includedExperiences?.forEach(exp => {
      incluedIds.push(exp.id);
    });
  });

  const [{ value: bestMatches }, ,] = useField('bestMatches');

  const [{ value: incompatibilities }, ,] = useField('incompatibilities');

  const [{ value: options }, ,] = useField(`extras`);
  const optionsIds = options?.map(item => item.id);

  const incompIds =
    incompatibilities?.length > 0
      ? incompatibilities?.map(item => item.value)
      : [];

  const suggestedOptionIds = [
    ...(suggestedExperience.value?.map(option => option.id) || []),
    ...incompIds,
    ...optionsIds?.filter(item => incluedIds.includes(item?.id)),
  ];

  const includedOptionIds = [
    ...(includedExperience.value?.map(option => option.id) || []),
    ...incompIds,
    ...optionsIds?.filter(item => suggestedIds.includes(item?.id)),
  ];

  const [pois, , { setValue }] = useField(`programs[${index}].pois`);
  const [currentIndex, setCurrentIndex] = useState(index);
  const exp = experiences?.value;
  const expLength = exp?.length;
  const [oldLength, setOldLength] = useState(expLength);

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);

  useEffect(() => {
    if (expLength > oldLength || (!program?.value && expLength > 0)) {
      setProgram(
        `${program?.value ?? ''}<br/><h1><b>${exp[expLength - 1]?.name
        }</b></h1><p>${exp[expLength - 1]?.description ?? ''}</p><br>`,
      );
    }
    setOldLength(expLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expLength]);

  return currentIndex === index && index >= 0 ? (
    <Container size="full">
      <Stack direction="column" gutterSize={1.5} size="full">
        <Stack size="full">
          <TextField
            required
            label={t('form.title')}
            content=""
            name={`programs[${index}].title`}
          />
        </Stack>

        <Stack gutterSize={1.5} size="full">
          <SearchablePlaceField
            label={t('form.startLocation')}
            name={`programs[${index}].startPosition`}
            required
            block
          />

          <Stack
            gutterSize={1}
            style={{
              flexWrap: 'wrap',
              alignItems: 'stretch',
            }}
          >
            <TextFieldButton
              type="number"
              step="1"
              label={t('form.startingTimeHour')}
              name={`programs[${index}].startingTimeHour`}
              content={'H'}
              min="0"
              max="23"
            />
            <TextFieldButton
              type="number"
              step="15"
              label={t('form.startingTimeMin')}
              name={`programs[${index}].startingTimeMin`}
              content={'min'}
              min="0"
              max="59"
            />
          </Stack>
        </Stack>

        <Stack gutterSize={1.5} size="full">
          <SearchablePlaceField
            label={t('form.endLocation')}
            name={`programs[${index}].endPosition`}
            required
            block
          />

          <Stack
            gutterSize={1}
            style={{
              flexWrap: 'wrap',
              alignItems: 'stretch',
            }}
          >
            <TextFieldButton
              type="number"
              step="1"
              label={t('form.endingTimeHour')}
              name={`programs[${index}].endingTimeHour`}
              content={'H'}
              min="0"
              max="23"
            />

            <TextFieldButton
              type="number"
              step="15"
              label={t('form.endingTimeMin')}
              name={`programs[${index}].endingTimeMin`}
              content={'min'}
              min="0"
              max="59"
            />
          </Stack>
        </Stack>

        <div className="w-full relative">
          {currentLocale.value !== initialLocale.value && (
            <div className="absolute z-10 h-full w-full flex justify-center items-center">
              <div className="text-orange-500 px-3 py-2 bg-white border border-orange-500 rounded">
                {t('program.experiences.selectEnglish')}
              </div>
            </div>
          )}
          <div
            className={
              currentLocale.value !== initialLocale.value &&
              'pointer-events-none opacity-50'
            }
          >
            <div className="w-full border rounded shadow p-5">
              <FormLabel>{t('form.POIs')}</FormLabel>
              <div className="my-2 flex flex-col space-y-2">
                {pois.value?.map((poi, index) => (
                  <div
                    className="flex justify-between items-center"
                    key={index}
                  >
                    <div>{poi.name}</div>

                    <button
                      type="button"
                      className="border-none text-red-500"
                      onClick={() => {
                        setValue(pois.value.filter((_, i) => i !== index));
                      }}
                    >
                      <svg className="h-4 w-4" viewBox="0 0 384 512">
                        <path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z" />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
              <MapsPlaceSearchField name={`programs[${index}].pois`} multiple />
            </div>
          </div>
        </div>

        <div className="w-full relative">
          {currentLocale.value !== initialLocale.value && (
            <div className="absolute z-10 h-full w-full flex justify-center items-center">
              <div className="text-orange-500 px-3 py-2 bg-white border border-orange-500 rounded">
                {t('program.experiences.selectEnglish')}
              </div>
            </div>
          )}
          <div
            className={
              currentLocale.value !== initialLocale.value &&
              'pointer-events-none opacity-50'
            }
          >
            <SearchExperience index={index} currentLocale={currentLocale} />
          </div>
        </div>

        <div className="w-full">
          <EditorField
            name={`programs[${index}].program`}
            label={t('form.program')}
            required
          />
        </div>

        <LocalMultiSearchableSelectField
          label={t('form.meals')}
          name={`programs[${index}].meals`}
          options={[
            { label: 'Breakfast', value: 'breakfast' },
            { label: 'Lunch', value: 'lunch' },
            { label: 'Dinner', value: 'dinner' },
          ]}
          renderOption={option => option.label}
          renderSelectedOption={option => option.label}
        />

        <Stack size="full">
          <MultiSearchableSelectField
            label={t('form.includedOptions')}
            name={`programs[${index}].includedExperiences`}
            query={getExperiences}
            fromNode={true}
            variables={{
              types: ['Activity', 'Transfer'],
              excludedExperiences: suggestedOptionIds,
              locale: currentLocale.value,
            }}
            pathToEdges={['experiences', 'edges']}
            renderOption={option => option.name}
            renderSelectedOption={option => option.name}
            includedIconList={{
              includedIcon: <IcBestMatch />,
              excludedIcon: '',
              list: bestMatches,
            }}
            useFieldNameToFilter={'extras'}
          />
        </Stack>

        <Stack size="full">
          <MultiSearchableSelectField
            label={t('form.suggestedOptions')}
            name={`programs[${index}].suggestedExperiences`}
            query={getExperiences}
            fromNode={true}
            variables={{
              types: ['Activity', 'Transfer', 'Extra'],
              excludedExperiences: includedOptionIds,
              locale: currentLocale.value,
            }}
            pathToEdges={['experiences', 'edges']}
            renderOption={option => option.name}
            renderSelectedOption={option => option.name}
            includedIconList={{
              includedIcon: <IcBestMatch />,
              excludedIcon: '',
              list: bestMatches,
            }}
            useFieldNameToFilter={'extras'}
          />
        </Stack>
        <Stack size={'full'}>
          <section className="grid grid-cols-1 divide-y w-full">
            {suggestedExperience?.value?.map((exp, seIndex) => {
              return (
                <details className="group py-1 text-sm w-full" key={seIndex}>
                  <summary className="flex cursor-pointer flex-row items-center justify-between w-full font-semibold text-gray-800 marker:[font-size:0px]">
                    {exp.name} options
                    <svg
                      className="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </summary>

                  <div className={'flex flex-col gap-4'}>
                    <MultiSearchableSelectField
                      label={t('steps.options.requiredOptions')}
                      name={`programs[${index}].suggestedExperiences[${seIndex}].required`}
                      query={getExperiences}
                      fromNode={true}
                      variables={{
                        types: ['Activity', 'Transfer', 'Extra'],
                        excludedExperiences: includedOptionIds,
                        locale: currentLocale.value,
                      }}
                      pathToEdges={['experiences', 'edges']}
                      renderOption={option => option.name}
                      renderSelectedOption={option => option.name}
                      includedIconList={{
                        includedIcon: <IcBestMatch />,
                        excludedIcon: '',
                        list: bestMatches,
                      }}
                      useFieldNameToFilter={'extras'}
                    />

                    <MultiSearchableSelectField
                      label={t('steps.options.suggestedOptions')}
                      name={`programs[${index}].suggestedExperiences[${seIndex}].suggested`}
                      query={getExperiences}
                      fromNode={true}
                      variables={{
                        types: ['Activity', 'Transfer', 'Extra'],
                        excludedExperiences: includedOptionIds,
                        locale: currentLocale.value,
                      }}
                      pathToEdges={['experiences', 'edges']}
                      renderOption={option => option.name}
                      renderSelectedOption={option => option.name}
                      includedIconList={{
                        includedIcon: <IcBestMatch />,
                        excludedIcon: '',
                        list: bestMatches,
                      }}
                      useFieldNameToFilter={'extras'}
                    />
                  </div>
                </details>
              );
            })}
          </section>
        </Stack>

        <div className="my-2 w-full flex flex-col space-y-5">
          <SegmentedControlField
            required
            name={`programs[${index}].accomodationType`}
            options={[
              { label: t('form.hotel'), value: 'hotel' },
              { label: t('form.nightOnBoard'), value: 'nightTransfer' },
              { label: t('form.noAccomodation'), value: 'noAccomodation' },
              { label: t('form.freeAccomodation'), value: 'freeAccomodation' },
            ]}
            label={t('form.accomodationType')}
          />

          {accomodationType?.value === 'hotel' && (
            <>
              <div className="mt-5">
                <SegmentedControlField
                  label={t('form.includedAccomodation')}
                  name={`programs[${index}].isIncludedAccomodation`}
                  options={[
                    { label: t('form.no'), value: false },
                    { label: t('form.yes'), value: true },
                  ]}
                />
              </div>

              <SearchableSelectField
                label={t('form.hotel')}
                name={`programs[${index}].accomodationHotel`}
                query={GET_HOTELS}
                pathToEdges={['hotels', 'edges']}
                renderOption={option => option.displayName}
                renderSelectedOption={option => option.displayName}
                required
              />

              <MultiSearchableSelectField
                label={isIncludedAccomodation?.value === true ? t('form.alternativeHotels') : t('form.suggestedHotels')}
                name={`programs[${index}].alternativeHotels`}
                query={GET_HOTELS}
                pathToEdges={['hotels', 'edges']}
                renderOption={option => option.displayName}
                renderSelectedOption={option => option.displayName}
              />
            </>
          )}

          {accomodationType?.value === 'nightTransfer' && (
            <MultiSearchableSelectField
              label={t('form.nightOnBoard')}
              name={`programs[${index}].accomodationExperience`}
              query={getExperiences}
              fromNode={true}
              variables={{
                types: ['Transfer'],
                time: 'night',
                locale: currentLocale.value,
              }}
              pathToEdges={['experiences', 'edges']}
              renderOption={option => option.name}
              renderSelectedOption={option => option.name}
              required
            />
          )}
        </div>
      </Stack>
      <div className="">
        <AvatarField
          label={t('form.program_picture')}
          name={`programs[${index}].picture`}
          shape="square"
          url={attachment?.value?.imageUrl}
          forImage
        />
      </div>
    </Container>
  ) : (
    <div className="h-32 flex justify-center items-center">
      <div className="text-center">
        <svg className="mb-1 h-12 w-12 mx-auto" viewBox="0 0 512 512">
          <path d="M144 0c8.8 0 16 7.2 16 16V64H352V16c0-8.8 7.2-16 16-16s16 7.2 16 16V64h32c35.3 0 64 28.7 64 64v32 32V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V192 160 128c0-35.3 28.7-64 64-64h32V16c0-8.8 7.2-16 16-16zM448 192H64V448c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32V192zM416 96H96c-17.7 0-32 14.3-32 32v32H448V128c0-17.7-14.3-32-32-32zM352 336c0 8.8-7.2 16-16 16H272v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V352H176c-8.8 0-16-7.2-16-16s7.2-16 16-16h64V256c0-8.8 7.2-16 16-16s16 7.2 16 16v64h64c8.8 0 16 7.2 16 16z" />
        </svg>
        <KoobTitle size="text-xl">{t('form.addProgramDay')}</KoobTitle>
      </div>
    </div>
  );
};
