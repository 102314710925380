import { Tooltip } from "../../../../components";
import { capitalize } from "lodash";

const resourceIcons = {
  experience: 'fa-layer-group',
  booking: 'fa-ticket',
  folder: 'fa-folder',
  nezasa: 'fa-plug'
};

export default function ApiKeyPermissions({ permissions = [] }) {
  const formattedPermissions = Object.fromEntries(
    Object.entries(
      permissions.reduce((acc, permission) => {
        const [action, resource] = permission.split('_');
        acc[resource] = acc[resource] || [];
        acc[resource].push(action);
        return acc;
      }, {})
    ).sort(([resourceA], [resourceB]) => resourceA.localeCompare(resourceB))
  );

  return (
    <div className="flex flex-wrap gap-1.5">
      {Object.entries(formattedPermissions).map(([resource, actions], index) => {
        const icon = resourceIcons[resource] || 'fa-question-circle';
        const tooltipContent = actions.map(action => capitalize(action)).join(', ');

        return (
          <Tooltip
            key={index}
            tip={`${capitalize(resource)}: ${tooltipContent}`}
            position="top"
          >
            <div className="px-2 py-1 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors">
              <div className="flex space-x-1 items-center">
                <i
                  className={[
                    'far',
                    icon,
                  ].join(' ')}
                />

                <div className="text-xs font-medium">
                  {actions?.includes('create') && 'C'}
                  {actions?.includes('read') && 'R'}
                  {actions?.includes('update') && 'U'}
                  {actions?.includes('delete') && 'D'}
                </div>
              </div>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}
