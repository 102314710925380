import { get, patch, post } from './';
import { API_NODE_BASE } from '../../constants';

export const getHotels = ({ filters, locale }) =>
  post(`/hotels?locale=${locale ?? 'en'}`, filters);

export const getHotel = async ({ hotelId, skip, locale }) => {
  if (skip) {
    return Promise.resolve(null);
  }

  return get(`/hotels/${hotelId}?locale=${locale ?? 'en'}`);
};

export const getHotelAllotments = async ({ hotelId, from, to }) => {
  return get(`/hotels/${hotelId}/allotments?from=${from}&to=${to}`);
};

export const updateHotelAllotments = async ({ hotelId, payload }) => {
  return patch(`/hotels/${hotelId}/allotments`, payload);
};

/**
 *
 * @param {string} hotelId
 * @param {UpdateTimezoneRequest} timezone
 */
export const updateHotelTimezone = async (hotelId, timezone) => {
  return patch(`/hotels/${hotelId}/timezone`, {
    timezone: timezone.timezone,
  });
};

export const getRoomsAvailable = ({ id, filters }) =>
  post(`hotels/${id}/roomsAvailable`, filters, {
    baseURL: `${API_NODE_BASE}/api/v2`,
  });

export const getRoomsCompositionPolicies = ({ policiesRequest }) =>
  post(`hotels/roomsPolicies`, policiesRequest, {
    baseURL: `${API_NODE_BASE}/api/v2`,
  });

export const submitBook = ({ id, data }) => {
  return post(`hotels/${id}/book`, data);
};

export const toggleIsChannelManagerConnected = async ({ hotelId, connected }) => {
  return patch(`/hotels/${hotelId}/toggle-channel-manager`, {enable: connected});
};

export const getHotelsV2 = ({ filters, locale, skip }) => {
  if (skip) {
    return Promise.resolve(null);
  }

  return post(`/hotels?locale=${locale ?? 'en'}`, filters, {
    baseURL: `${API_NODE_BASE}/api/v2`,
  });
}
